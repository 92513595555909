<template>
  <div class="recommend-wrap" @click="goDetail">
    <img :src="item.serviceImage"  class="recommend-img" alt="0"/>
    <div class="recommend-right">
      <span>{{ item.consultantName.length > 3 ? item.consultantName.slice(0, 3) + '...' : item.consultantName }}</span>
      <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          :content="item.serviceName">
        <span style="font-size: 12px; color: #4E6FDA;" slot="reference">{{ item.serviceName.length > 5 ? item.serviceName.slice(0, 5) + '...' : item.serviceName }}</span>
      </el-popover>
      <span>{{ item.level }}</span>

      <div style="font-size: 12px;">
        服务时常：{{ item.serviceDuration }}分钟
      </div>

      <el-popover
          placement="top-start"
          width="200"
          trigger="hover"
          :content="item.serviceContent">
        <div class="desc" slot="reference" v-html="item.serviceContent"></div>
      </el-popover>
      <el-rate :value="item.score" disabled/>
      <!--底部-->
      <div class="footer">
        <div class="price">
          <span>￥</span>
          <span>{{ item.price }}</span>
        </div>
        <div class="btn-price">立即咨询</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['item'],
  methods: {
    goDetail() {
      this.$router.push(`/consultDetails?id=${this.item.id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.recommend-wrap {
  box-sizing: border-box;
  padding: 13px 12px 16px 0;
  margin: 0 auto 20px;
  width: 100%;
  height: 176px;
  background: #FFFFFF;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  display: flex;
}

.recommend-img {
  width: 40%;
  height: 149px;
  background: #DA864E;
  border-radius: 6px;
  object-fit: cover;
}

.recommend-right {
  margin-left: 12px;
  width: calc(60% - 12px);

  span:nth-child(1) {
    font-size: 16px;
    font-weight: bold;
    color: #000000;
  }

  span:nth-child(2) {
    padding: 1px 4px 3px 5px;
    display: inline-block;
    background: rgba(78, 111, 218, 0.19);
    border-radius: 100px;
    text-align: center;
    line-height: 18px;
    color: #4E6FDA;
    font-size: 12px;
    transform: scale(0.83);
  }

  span:nth-child(3) {
    padding: 1px 8px 3px 11px;
    background: rgba(218, 181, 78, 0.19);
    border-radius: 100px;
    text-align: center;
    line-height: 18px;
    color: #DA864E;
    font-size: 12px;
    transform: scale(0.83);
  }
}

.desc {
  margin-top: 4px;
  height: 51px;
  font-size: 12px;
  line-height: 17px;
  color: #5D5D5D;
  overflow: hidden;
}

.price {

  span:nth-child(1) {
    font-size: 9px;
    color: #535457;
    font-weight: bold;
  }

  span:nth-child(2) {
    font-size: 17px;
    color: #4E6FDA;
    font-weight: bold;
    background: #ffffff;
  }
}

.btn-price {
  width: 50%;
  height: 24px;
  background: #4E6FDA;
  border-radius: 100px;
  text-align: center;
  line-height: 24px;
  color: #ffffff;
  font-size: 12px;
  cursor: pointer;
}

.footer {
  margin-top: 3px;
  display: flex;
  justify-content: space-between;
}
</style>
