<template>
  <div class="details-wrap">
    <el-card>
      <!-- 头部标题 -->
      <el-row>
        <header>
          <div @click="$router.back()">
            <img alt="0" class="back" src="../assets/back@2.png"/>
            <span class="back-font">返回</span>
          </div>
        </header>
      </el-row>
      <!--内容-->
      <main>
        <el-row class="main" style="margin-top: 9px">
          <!--左边-->
          <!-- <el-col :span="8" style="border-right: 1px solid #F1F1F1; height: 100vh; padding-right: 15px"> -->
            <!--心理咨询服务-->
            <!-- <div style="padding-left: 20px">
              <div class="btn">
                {{ item.serviceName }}
              </div>
              <div class="abstract" style="margin-top: 20px">
                <span class="desc-title">概要：</span>
                <div slot="reference" class="reference-content" v-html="item.serviceOutline"></div>

              </div>
              <img :src="item.serviceImage" alt="0" class="img-content"/>
              <div class="content" style="margin-top: 18px">
                <span class="desc-title">服务内容：</span>
                <div slot="reference" v-html="item.serviceContent"></div>
              </div>
            </div> -->

            <!--分隔线-->
            <!-- <div class="cut-line"/> -->

            <!--评论-->
            <!-- <div>
              评论头部
              <div class="comment-head">
                <div style="margin-left: 25px">
                  <span class="comment-font">评价</span>
                  <span class="comment-num">{{ item.score }}</span>
                </div>
                <el-rate :value="item.score" disabled style="margin-right: 33px"/>
              </div>
              评论内容
              <div style="overflow-y: auto; height: 550px; overflow-x: hidden">
                <Comment v-for="commentItem in commentList" :item="commentItem"/>
              </div>
            </div> -->
          <!-- </el-col> -->

          <!--中间-->
          <el-col :span="10" class="counsel-wrap">
            <img :src="item.avatar" alt="0" class="center-avatar"/>
            <!-- <div class="center-name">{{ item.consultantName }}</div>
            <div class="consultant-label">{{ item.level }}</div> -->
            <!-- <div class="profile">
              <div class="introduction">个人介绍</div>
              <div class="profile-desc" v-html="item.profile">
              </div>
            </div> -->
            <!--服务价格-->
            <div class="service-price">
              <!-- <div class="price">
                <span>￥</span>
                <span>{{ item.price }}</span>
              </div> -->
              <div class="service-time">服务时长：{{ item.serviceDuration }}分钟；剩余次数：{{
                  item.buyNum - item.useNum || 0
                }}次
              </div>
            </div>
            <div class="btn-two">
              <span v-if="serviceRecordId" @click="handleCreateRoom">立即咨询</span>
              <!-- <span v-else @click="handleServiceConsult">立即购买</span> -->
            </div>
            <!--<div class="btn-one">预约咨询</div>-->
          </el-col>

          <!--右边-->
          <!-- <el-col :span="6" style="background: #E4E4E4; border-radius: 5px;">
            <div class="recommend">其他推荐</div>
            <div class="recommend-list no-" style="overflow-y: auto">
              <RecommendConsult v-for="recommendItem in recommendList" :key="recommendItem.id" :item="recommendItem"/>
            </div>
          </el-col> -->

        </el-row>
      </main>
    </el-card>

    <!--视频弹窗-->
    <Video ref="videoPopup" @success="buyServiceState"/>
  </div>
</template>

<script>
import Comment from "../components/Comment";
import RecommendConsult from "../components/RecommendConsult";
import {getConsultantList} from '../api/consultantEvaluate';
import {getCounselList, checkConsultState, buyConsultService, isBuyService, teacherDetail} from '@/api/counselService';
import Video from "../components/video";
import {mapMutations, mapState} from 'vuex';
import {getFeaturedCounsel} from '../api/home';
import user from "../layout/components/user";

export default {
  data() {
    return {
      counselorDetail: {},
      item: {}, // 当前咨询师详情
      commentList: [], // 评论列表
      recommendList: [], // 推荐列表
      currPage: 0, // 当前页
      totalPage: 1, // 总页数
      serviceRecordId: null, // 购买服务状态
      id: ''
    }
  },
  components: {
    Comment,
    RecommendConsult,
    Video
  },
  computed: {
    ...mapState(['roomId', 'user'])
  },
  async created() {
    this.init()
  },
  mounted() {
  },
  watch: {
    '$route.query.id': {
      handler(e) {
        this.init()
        // this.$router.push(`/consultDetails?id=${this.item.id}`)
      },
      deep: true
    },
  },
  methods: {
    ...mapMutations(['randomRoomIdOruserId']),
    async init() {
      this.id = this.$route.query.id
      const {data} = await teacherDetail(this.id)
      this.item = data
      // this.item = JSON.parse(decodeURIComponent(this.$route.params.detail))
      this.acquireConsultantInfo();
      this.load();
      this.buyServiceState();
    },
    // 获取咨询师详情信息
    acquireConsultantInfo() {
      getConsultantList(this.item.id).then(({data}) => {
        this.commentList = data;
      })
    },

    // 其他推荐滚动 加载数据
    load() {
      // if (this.currPage > this.totalPage) {
      //   return
      // }

      getFeaturedCounsel().then(({data}) => {

        // this.totalPage = data.totalPage;

        // 筛选推荐列表
        // this.recommendList = [...data.list.filter(item => item.id !== this.item.id),]

        this.recommendList = data

      })
      // this.count += 2
    },

    // 创建咨询房间
    async handleCreateRoom() {
      // 状态管理中存入房间号, 用户id
      this.randomRoomIdOruserId()
      checkConsultState(this.item.consultantId).then(data => {
        this.$store.commit('SET_ROOM', this.item.consultantId)
        const obj = {
          roomId: this.item.consultantId,
          teacherId: this.item.consultantId,
          // serviceDuration: this.item.serviceDuration,
          price: this.item.price,
          contentId: this.item.id,
          serviceRecordId: this.serviceRecordId,
        }
        this.$refs.videoPopup.open(obj)
      }).catch(e => {
        this.$message.error(e.msg)
      })

    },

    // 购买服务咨询
    handleServiceConsult() {
      this.$prompt('请输入购买次数', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: /^[0-9]*$/,
        closeOnClickModal: false,
        closeOnPressEscape: false,
        inputErrorMessage: '请输入数字',
      }).then(({value}) => {
        buyConsultService({
          buyNum: value,
          customerId: this.user.id,
          contentId: this.item.id
        }).then(res => {
          this.buyServiceState();
        })
      })

    },

    // 购买状态
    buyServiceState() {
      isBuyService({
        contentId: this.item.id,
        customerId: this.user.id
      }).then(({data}) => {
        if (data) {
          this.$set(this, 'serviceRecordId', data.id)
          this.$set(this.item, 'buyNum', data.buyNum)
          this.$set(this.item, 'useNum', data.useNum)
        }
      }).catch(err => {
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.desc-title {
  font-weight: bold;
  font-size: 16px;
  color: #101010;
}

.counsel-wrap {
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
}

header {
  border-bottom: 1px solid #F1F1F1;
  padding-bottom: 11px;
}

.back {
  width: 18.6px;
  height: 18.6px;
  margin-bottom: -3px;
  cursor: pointer;
}

.back-font {
  margin-left: 10px;
  font-size: 20px;
  color: #292929;
  cursor: pointer;
}

.btn {
  margin-top: 17px;
  height: 37px;
  border: 1px solid #4E6FDA;
  border-radius: 5px;
  font-size: 18px;
  text-align: center;
  line-height: 37px;
  color: #4E6FDA;
  padding: 0 10px;
  display: inline;
}

.abstract,
.content {
  width: 100%;
  //height: 97px;
}

.img-content {
  width: 100%;
  height: 190px;
  background: tomato;
  opacity: 0.5;
  border-radius: 10px;
}

.cut-line {
  width: 100%;
  height: 5px;
  background: #F1F1F1;
  opacity: 0.5;
  margin: 20px 0;
}

.comment-head {
  padding: 11px 0 11px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #F1F1F1;
}

.comment-font {
  font-size: 20px;
  color: #000000;
}

.comment-num {
  margin-left: 11px;
  font-size: 24px;
  color: #FDAA01;
  font-weight: bold;
}

.center-avatar {
  display: inline-block;
  width: 123px;
  height: 123px;
  border-radius: 106px;
}

.center-name {
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #000000;
}

.consultant-label {
  margin: 9px auto;
  width: 136px;
  height: 31px;
  background: rgba(218, 181, 78, 0.19);
  border-radius: 100px;
  line-height: 31px;
  font-size: 16px;
  color: #DA864E;
  text-align: center;
}

.profile {
  margin: 0 auto;
  width: 100%;
  //height: 300px;
  font-size: 18px;
  color: #828282;
  line-height: 25px;
  text-align: left;
}

.introduction {
  margin: 0 auto 21px;
  width: 80px;
  height: 28px;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
  color: #0D0D0D;
}

.service-price {
  width: 100%;
  margin: 38px auto 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.price {
  span:nth-child(1) {
    font-size: 16px;
    color: #535457;
    font-weight: bold;
  }

  span:nth-child(2) {
    font-size: 28px;
    color: #4E6FDA;
    font-weight: bold;
  }
}

.service-time {
  font-size: 13px;
  line-height: 18px;
  color: #303030;
}

.btn-two {
  margin: 14px auto 0;
  width: 100%;

  span:nth-child(1) {
    display: inline-block;
    width: 275px;
    height: 50px;
    background: #DF5D37;
    border-radius: 100px;
    text-align: center;
    line-height: 50px;
    color: #FFFFFF;
    font-size: 18px;
    margin-right: 20px;
    cursor: pointer;
    margin-right: 0;
  }

  span:nth-child(2) {
    display: inline-block;
    width: 275px;
    height: 50px;
    background: #4E6FDA;
    border-radius: 100px;
    text-align: center;
    line-height: 50px;
    color: #FFFFFF;
    font-size: 18px;
    cursor: pointer;
  }
}

.btn-one {
  margin: 27px auto 0;
  width: 570px;
  height: 50px;
  background: #FDAA01;
  border-radius: 100px;
  text-align: center;
  line-height: 50px;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}

.recommend {
  margin: 11px 0 0 17px;
  font-size: 18px;
  line-height: 25px;
  color: #1E1E1E;
}

.recommend-list {
  height: 896px;
  margin-top: 10px;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.reference-content {
  color: #858585;
  font-size: 15px;
  line-height: 17px;
  height: 51px;
}

.profile-desc {
  width: 100%;
  padding: 0 10px;
  word-wrap: break-word
}
::v-deep .el-col-10 {
  width: 100%;
}
</style>
