<template>
  <div class="comment-wrap">
    <div class="comment-up">
      <img class="comment-avatar" :src="item.customerAvatar" alt="0" />
      <span class="name">{{ item.customerName }}</span>
      <el-rate disabled :value="item.serviceScore" />
    </div>
    <div class="comment-content">{{ item.comment }}</div>
    <div class="comment-time">{{ item.commentTime ? formatDate(item.commentTime, 'YYYY.MM.DD HH:mm') : '' }}</div>
  </div>
</template>

<script>
const dayjs = require('dayjs');

export default {
  props: ['item'],
  methods: {
    formatDate(time, format) {
      return dayjs(time).format(format)
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-wrap {
  width: 500px;
  margin-top: 20px;
  padding-left: 24px;
  border-bottom: 1px solid #F1F1F1;
  padding-bottom: 16px;
}

.comment-up {
  display: flex;
  align-items: center;
}

.comment-avatar {
  display: inline-block;
  width: 26px;
  height: 26px;
  background: pink;
  border-radius: 50%;
}

.name {
  margin: 0 13px 0 10px;
}

.comment-content {
  width: 480px;
  margin-top: 13px;
  color: #727272;
  font-size: 16px;
}

.comment-time {
  margin-top: 9px;
  color: #C9C9C9;
  font-size: 16px;
}
</style>
